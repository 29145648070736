import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { ReactNode, useEffect, useState } from 'react'
interface headProps {
	title?: string | null
	description?: string | null
	keywords?: string | null
	children?: ReactNode
	ogImg?: string
	status?: string
	do_index?: string
	isCanonical?: string
	canonicalUrl?: string
}
function MetaTags(props: headProps) {
	const router = useRouter()

	const [canonicalLink, setCanonicalLink] = useState('')

	useEffect(() => {
		const link = props?.isCanonical === 'Y' && props?.canonicalUrl ? `https://www.gerdentusa.com/${props?.canonicalUrl}` : `https://www.gerdentusa.com${router.asPath}`

		setCanonicalLink(link)
	}, [router.asPath, props?.isCanonical, props?.canonicalUrl])

	return (
		<Head key={router.asPath}>
			{/* SEO */}
			<title>{props?.title}</title>
			<meta lang='en-US' name='viewport' content='width=device-width, initial-scale=1' />
			<meta name='description' content={props?.description} />
			<meta name='keywords' content={props?.keywords} />
			<meta name='author' content={'GermedUSAIT'} />
			<link href={canonicalLink} rel='canonical' />
			{(props?.status === 'N' || props?.do_index === 'N') && <meta name='robots' content='noindex' />}

			<link href='https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=swap' rel='stylesheet' />

			{/* Google Site Verification */}
			<meta name='google-site-verification' content='mKsVp8_wy6nMOyT-yL6pV9nSNqOXE3HwZs_jgEPbJqk' />

			{/* for windows */}
			<meta name='msapplication-TileColor' content='#52a0f2' />
			<meta name='msapplication-TileImage' content='/assets/icons/favicons/logo-32x32.png' />
			<meta name='msapplication-config' content='/assets/icons/favicons/browserconfig.xml' />

			{/* for facebook */}
			<meta property='og:title' content={props?.title} />
			<meta property='og:site_name' content='GerDentUSA' />
			<meta property='og:url' content={`https://www.gerdentusa.com${router.asPath}`} />
			<meta property='og:description' content={props?.description} />
			<meta property='og:type' content='website' />
			<meta property='og:image' content={props?.ogImg ? props?.ogImg : '/assets/icons/logo.png'} />
			<meta property='og:image:width' content='1200' />
			<meta property='og:image:height' content='630' />

			{/* Google Tag Manager */}
			<script
				dangerouslySetInnerHTML={{
					__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','GTM-M328CR6');
                     `
				}}
			/>

			{/* Google Analytics */}
			<script async src='https://www.googletagmanager.com/gtag/js?id=G-4RXKRQB5X0'></script>
			<script
				dangerouslySetInnerHTML={{
					__html: `  window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                   gtag('config', 'G-4RXKRQB5X0');
                   `
				}}
			/>

			{/*for twitter */}
			<meta name='twitter:card' content='summary_large_image' />
			<meta name='twitter:site' content='@Gerdentusa' />
			<meta name='twitter:creator' content='@Gerdentusa' />
			<meta name='twitter:title' content={props?.title} />
			<meta name='twitter:description' content={props?.description} />
			<meta name='twitter:image' content={props?.ogImg ? props?.ogImg : '/assets/icons/logo.png'} />

			{/* favicons */}
			<link rel='shortcut icon' type='image/png' sizes='16x16' href='/assets/icons/favicons/logo-16x16.png' />
			<link rel='shortcut icon' type='image/png' sizes='32x32' href='/assets/icons/favicons/logo-32x32.png' />
			<link rel='apple-touch-icon' sizes='180x180' type='image/png' href='/assets/icons/favicons/logo-180x180.png' />
			<link rel='shortcut icon' type='image/x-icon' href='/assets/icons/favicons/favicon.ico' />
			<link rel='mask-icon' href='/assets/icons/favicons/white-logo.svg' color='#52a0f2' />

			{props?.children}
		</Head>
	)
}

export default MetaTags
